import { LANG } from '../translation';
import { resizeS3Image } from '../util/resizeS3Image';

export const SERVICE_DOMAINS = {
  development: process.env.DOMAIN ?? 'localhost',
  staging: process.env.DOMAIN ?? 'mauth.marpple.tw',
  production: process.env.DOMAIN ?? 'mauth.marpple.com',
};

export const SERVICE_ENV = (process.env.NODE_ENV ?? 'development') as 'development' | 'production' | 'staging';

export const SERVICE_DOMAIN = SERVICE_DOMAINS[SERVICE_ENV];
export const SERVICE_PORT = SERVICE_ENV === 'development' ? ':4001' : '';
export const SERVICE_PROTOCOL = SERVICE_ENV === 'development' ? 'http://' : 'https://';

export const SESSION_COOKIE_NAME = 'mco-auth-s.id';

export const MARPPLE_DOMAIN = {
  development: process.env.DOMAIN ? process.env.DOMAIN + ':9026' : 'localhost:9026',
  staging: 'mpcom.marpple.cc',
  production: 'www.marpple.com',
};

export const MARPPLESHOP_DOMAIN = {
  development: process.env.DOMAIN ?? 'localhost:11001',
  staging: 'marpple.tw',
  production: 'marpple.shop',
};

export const CIETY_DOMAIN = {
  development: 'www.ciety.dev',
  staging: 'www.ciety.dev',
  production: 'www.ciety.xyz',
};

export const DF_DOMAIN = {
  development: process.env.DOMAIN ?? 'localhost:9022',
  staging: 'mpdf.marpple.cc',
  production: 'df.marpple.com',
};

export const NEXON_ESSENTIAL_DOMAIN = {
  development: process.env.DOMAIN ?? 'dev.essential.nexon.com:9111',
  staging: 'stage.essential.nexon.com',
  production: 'essential.nexon.com',
};

export const MCO_DOMAIN = {
  Marpple: SERVICE_PROTOCOL + MARPPLE_DOMAIN[SERVICE_ENV],
  MarppleShop: SERVICE_PROTOCOL + MARPPLESHOP_DOMAIN[SERVICE_ENV],
  Ciety: SERVICE_PROTOCOL + CIETY_DOMAIN[SERVICE_ENV],
};

export const MCO_WITHDRAW_DOMAIN = {
  Marpple: SERVICE_PROTOCOL + MARPPLE_DOMAIN[SERVICE_ENV],
  MarppleShop: SERVICE_PROTOCOL + MARPPLESHOP_DOMAIN[SERVICE_ENV],
  Ciety: SERVICE_PROTOCOL + CIETY_DOMAIN[SERVICE_ENV],
};

export const MARPPLE_APP_SCHEME = {
  development: 'marppleappdev://marppleapp',
  staging: 'marppleappstaging://marppleapp',
  production: 'marppleapp://marppleapp',
} as const;

export const MARPPLESHOP_APP_SCHEME = {
  development: 'marppleshopdev://marppleshop',
  staging: 'marppleshopstaging://marppleshop',
  production: 'marppleshop://marppleshop',
} as const;

export const CIETY_APP_SCHEME = {
  development: 'ciety-dev://mauth',
  staging: 'ciety-stage://mauth',
  production: 'ciety://mauth',
} as const;

export const APP_SCHEME = {
  Marpple: MARPPLE_APP_SCHEME[SERVICE_ENV],
  MarppleShop: MARPPLESHOP_APP_SCHEME[SERVICE_ENV],
  Ciety: CIETY_APP_SCHEME[SERVICE_ENV],
} as const;

export const APP_USER_AGENT = {
  Marpple: 'MarppleApp',
  MarppleShop: 'MarppleShopApp',
  Ciety: 'cietyApp',
};

export const SERVICE_CONTACT = {
  Marpple: {
    tel: '1566-9437',
    mailto: 'help@marpple.com',
  },
  MarppleShop: {
    tel: '1566-5496',
    mailto: 'cs@marppleshop.com',
  },
  Ciety: {
    tel: '',
    mailto: 'contact@ciety.io',
  },
} as const;

export const APP_LOGIN_PATH = '/oauth';

export const MCO_CONNECT_FAQ_PATH = {
  Marpple: `/${LANG}/help_center?type=order_pay_kr&hg_id=539&hp_id=2074`,
  MarppleShop: `/${LANG}/@/help?type=creator_mall_customer_faq_kr&hg_id=345&hp_id=2070`,
  // TODO: 수정 필요
  Ciety: `/${LANG}/@/help?type=creator_mall_customer_faq_kr&hg_id=345&hp_id=2070`,
};

export const MCO_OLD_USER_FAQ_PATH = {
  Marpple: `/${LANG}/help_center?type=order_pay_kr&hg_id=539&hp_id=2074`,
  MarppleShop: `/${LANG}/@/help?type=creator_mall_customer_faq_kr&hg_id=345&hp_id=2069`,
};

export const MCO_PRIVACY_PATH = `/${LANG}/terms/privacy`;

export const MCO_TERMS_PATH = `/${LANG}/terms/service`;

export const MCO_MARKETING_PATH = `/${LANG}/terms/privacy#privacy3`;

export const MCO_ORDER_PATH = {
  Marpple: `/${LANG}/order`,
  MarppleShop: `/${LANG}/@/orders`,
  NexonEssential: `/${LANG}/@/orders`,
  Ciety: '/',
};

export const LOGIN_IMAGE = {
  Marpple: resizeS3Image({
    url: '//s3.marpple.co/files/u_2283830/2024/9/original/8d9cb4e487a0ffaed0735ed8b0eeb9848cc7ddfd1.png',
    quality: 80,
    width: 1200,
    format: 'avif',
  }),
  MarppleShop: resizeS3Image({
    url: '//s3.marpple.co/files/u_2283830/2024/8/original/8cad17d1591a24580f53bf415c349d39bbada44b1.png',
    quality: 80,
    width: 1200,
    format: 'avif',
  }),
  Ciety: resizeS3Image({
    url: '//s3.marpple.co/files/u_2283830/2025/3/original/408c6c2b28ddbad7e872acf0783115c7551268ce1.png',
    quality: 80,
    width: 1200,
    format: 'avif',
  }),
};

export const SIGNUP_COMPLETE_IMAGE = {
  Marpple: resizeS3Image({
    url: '//s3.marpple.co/files/u_2283830/2024/8/original/63766c9b4eccb7434f8c3bde37d7c24b5fd9f72a1.png',
    quality: 80,
    width: 900,
    format: 'avif',
  }),
  MarppleShop: resizeS3Image({
    url: '//s3.marpple.co/files/u_2283830/2024/4/original/57c0f4dbb3b454bf021c71464c18e0a94c5346861.png',
    quality: 80,
    width: 900,
    format: 'avif',
  }),
  Ciety: resizeS3Image({
    url: '//s3.marpple.co/files/u_2283830/2025/2/original/ea63d95d1b5d2f4b637da6901eb4094f5185c7b01.png',
    quality: 80,
    width: 900,
    format: 'avif',
  }),
};

export const SERVICE = {
  Marpple: 'Marpple',
  MarppleShop: 'MarppleShop',
  Ciety: 'Ciety',
} as const;

export const SERVICE_CORS_ORIGIN = {
  ['production']: RegExp(
    `(^(https):\\\\/\\\\/)(?:[a-z0-9]+\\\\.)?(marpple|webtoonfriends)\\\\.(shop|cc|io|tw|com)|(^(https):\\/\\/)(?:[a-z0-9]+\\.)?ciety\\.xyz`,
  ),
  ['staging']: RegExp(`https?:\\/\\/.*`),
  ['development']: RegExp(`https?:\\/\\/.*`),
};
